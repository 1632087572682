.service {
  width: 50%;
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  .success {
    color: green;
  }
  .error {
    color: red;
  }
}
